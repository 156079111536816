import React from 'react';
import './Vision.css';

function Vision() {

  return (
    <div className="vision">
      <i className="vision-image fas fa-angle-double-up"></i>
      <div className="vision-container">
          <h1 className="vision-text">We build bespoke web and mobile applications designed around your vision so as not to restrict the goals you have for your business</h1>
      </div>
    </div>
  );
}

export default Vision;