import React from 'react';
import './Footer.css';
import { Button } from './Button';

function Footer() {

  const sendMail = (event) => {

    event.preventDefault();

  }

  return (
    <div className='footer-container'>
      {/*
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          If you would be interested in working with us, get in touch
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='name'
              type='name'
              placeholder='Your Name'
            />
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <br />
            <textarea
              className='footer-textarea'
              name='subject'
              type='subject'
              placeholder='Subject'
            />
            <Button buttonStyle='btn--outline' onClick={sendMail}>Send <i className='fa fa-paper-plane' /></Button>
          </form>
        </div>
      </section>
      */}
      <section className='website-rights'>
          <small>NEBU STATION LTD. © 2020</small>
      </section>
    </div>
  );
}

export default Footer;