import React from 'react';
import './HeroSection.css';
import '../App.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/video.mp4' autoPlay loop muted />
      <h1>WEB &amp; MOBILE DEVELOPMENT</h1> 
      <p>Designed with you to deliver your vision</p>
    </div>
  )
}

export default HeroSection;